import { Component, OnInit } from '@angular/core';
import { NotificationService } from '../shared/notification/notification.service';
import { SpinnerService } from '../spinner/spinner.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'backend-static-build',
  templateUrl: './download-build.component.html',
  styleUrls: ['./download-build.component.scss'],
})
export class DownloadBuildComponent implements OnInit {
  public staticInfo = null;

  public constructor(
    private readonly spinnerService: SpinnerService,
    private readonly notificationService: NotificationService,
    private httpClient: HttpClient
  ) {}

  downloadBuild(buildItem: any) {
    this.spinnerService.showSpinner();
    if (buildItem && buildItem.buildFile && buildItem.buildFile.length > 0) {
      window.open('/' + buildItem.buildFile);
      this.spinnerService.hideSpinner();
    } else {
      this.spinnerService.hideSpinner();
      this.notificationService.error(
        'URL Error',
        'The URL does not exist!',
        null,
        null
      );
    }
  }

  ngOnInit() {
    this.httpClient
      .get(
        'assets/json/static-build-info.json?version=' +
          (Date.now() + Math.floor(100000 + Math.random() * 900000))
      )
      .subscribe((data) => {
        this.staticInfo = data;
      });
  }
}
