import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { NotificationComponent } from './notification/notification.component';
import { GetLangValuePipe } from '../get-lang-value.pipe';

@NgModule({
  declarations: [NotificationComponent, GetLangValuePipe],
  imports: [CommonModule, TranslateModule],
  exports: [NotificationComponent, GetLangValuePipe],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
