import { NotificationType } from './notification-type.enum';

export class Notification {
  public constructor(
    public type: NotificationType,
    public title: string,
    public message: string,
    public messageParams?: any,
    public details?: string
  ) {}
}
