<sh-notifications>
  <ng-container *ngFor="let notification of notifications">
    <sh-notification-item
      *ngIf="notification.type === 0"
      #notificationItem
      visible
      [type]="getType(notification.type)"
      [label]="notification.title | translate"
      duration="short"
    >
      {{ notification.message | translate : notification.messageParams }}
      <sh-accordion
        *ngIf="notification.details"
        flat
        [label]="'Error Details' | translate"
        [innerText]="notification.details | translate"
      ></sh-accordion>
      <sh-button
        *ngIf="notification.type > 0"
        slot="footer"
        shape="pill-m"
        [label]="'Close' | translate"
        (click)="notificationItem.visible = false"
      ></sh-button>
    </sh-notification-item>
    <sh-notification-item
      *ngIf="notification.type > 0"
      #notificationItem
      visible
      sticky
      [type]="getType(notification.type)"
      [label]="notification.title | translate"
    >
      {{ notification.message | translate : notification.messageParams }}
      <sh-accordion
        *ngIf="notification.details"
        flat
        [label]="'Error Details' | translate"
        [innerText]="notification.details | translate"
      ></sh-accordion>
      <sh-button
        *ngIf="notification.type > 0"
        slot="footer"
        shape="pill-m"
        [label]="'Close' | translate"
        (click)="notificationItem.visible = false"
      ></sh-button>
    </sh-notification-item>
  </ng-container>
</sh-notifications>
