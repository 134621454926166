import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ThemeService } from '../services/theme.service';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'backend-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  $auth: Observable<boolean>;
  $profile: Observable<any>;

  public contactInfo = null;

  public constructor(
    public themeService: ThemeService,
    public router: Router,
    private httpClient: HttpClient
  ) {}

  public onThemeToggleClick(event: MouseEvent): void {
    event.stopPropagation();
    this.themeService.toggle();
  }

  ngOnInit() {
    this.httpClient
      .get('assets/json/static-build-info.json')
      .subscribe((data) => {
        if (data && data['contacts']) {
          this.contactInfo = data['contacts'];
        }
      });
  }
}
