<sh-wrapper direction="column">
  <sh-text>
    <sh-text size="super-header">{{ staticInfo?.pageTitle }}</sh-text>
    <p>{{ staticInfo?.pageDesc }}</p>
  </sh-text>
  <div
    style="overflow-x: auto"
    *ngIf="staticInfo?.sections && staticInfo?.sections.length > 0"
  >
    <sh-table *ngFor="let section of staticInfo?.sections">
      <sh-table-row slot="header">
        <sh-table-head unsortable columns="8">
          <sh-text size="header-1" style="margin: 0px">
            {{ section.col1 }}
          </sh-text>
        </sh-table-head>
        <sh-table-head unsortable columns="12">
          {{ section.col2 }}
        </sh-table-head>
        <sh-table-head unsortable columns="4">
          {{ section.col3 }}
        </sh-table-head>
        <sh-table-head unsortable columns="4">
          {{ section.col4 }}
        </sh-table-head>
        <sh-table-head unsortable columns="5">
          {{ section.col5 }}
        </sh-table-head>
        <sh-table-head unsortable columns="5"></sh-table-head>
      </sh-table-row>
      <sh-table-row *ngFor="let build of section?.builds">
        <sh-table-cell
          columns="8"
          [innerText]="build.buildName"
        ></sh-table-cell>
        <sh-table-cell columns="12">
          <div *ngFor="let feature of build.features">
            <sh-text>&nbsp;{{ feature.featureHeader }}</sh-text>
            <sh-text *ngFor="let content of feature.featureContent">
              <sh-text *ngIf="content.includes('•')">{{ content }}</sh-text>
              <sh-text *ngIf="content.includes('‣')">
                &nbsp;&nbsp;&nbsp;&nbsp; {{ content }}</sh-text
              >
              <sh-text
                *ngIf="!content.includes('‣') && !content.includes('•')"
                >{{ content }}</sh-text
              >
            </sh-text>
          </div>
        </sh-table-cell>
        <sh-table-cell columns="4" [innerText]="build.version"></sh-table-cell>
        <sh-table-cell
          columns="4"
          [innerText]="build.size + ' MB'"
        ></sh-table-cell>
        <sh-table-cell columns="5" [innerText]="build.date"></sh-table-cell>
        <sh-table-cell columns="5">
          <sh-button
            size="pill-m"
            [disabled]="!build.buildFile || build.buildFile.length === 0"
            [label]="'Download' | translate"
            (click)="downloadBuild(build)"
          >
          </sh-button>
        </sh-table-cell>
      </sh-table-row>
    </sh-table>
  </div>
</sh-wrapper>
